import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { opacity } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  outline: 0;
  background-color: transparent;
  transition: background-color 0.3s ease, border 0.3s ease;
  cursor: pointer;
  border-radius: 50%;
  border: none;

  &:disabled {
    ${opacity.o50};
    pointer-events: none;
  }
`;

export const tiny = css`
  padding: ${spacing(8)};
  width: calc(var(--unit) * 8);
  height: calc(var(--unit) * 8);
  min-width: calc(var(--unit) * 8);
`;

export const small = css`
  padding: ${spacing(12)};
  width: calc(var(--unit) * 10);
  height: calc(var(--unit) * 10);
  min-width: calc(var(--unit) * 10);
`;

export const medium = css`
  padding: ${spacing(14)};
  width: calc(var(--unit) * 11);
  height: calc(var(--unit) * 11);
  min-width: calc(var(--unit) * 11);
`;

export const large = css`
  padding: ${spacing(20)};
  width: calc(var(--unit) * 14);
  height: calc(var(--unit) * 14);
  min-width: calc(var(--unit) * 14);
`;

export const primary = css`
  ${medium};
  background-color: hsl(var(--color-text-1));

  path {
    fill: hsl(var(--color-white-11));
  }

  &:hover,
  &:active {
    background-color: hsl(var(--color-text-2));
  }
`;

export const secondary = css`
  ${medium};
  background-color: hsl(var(--color-robin-2));

  path {
    fill: hsl(var(--color-text-1));
  }

  &:hover,
  &:active {
    background-color: hsl(var(--color-robin-4));
  }
`;
export const ghost = css`
  ${medium};

  path {
    fill: hsl(var(--color-grayscale-6));
    transition: fill 0.2s;
  }

  &:hover {
    path {
      fill: hsl(var(--color-text-1));
    }
  }
`;

export const neutral = css`
  ${medium};
  background-color: hsl(var(--color-grayscale-2));

  path {
    fill: hsl(var(--color-text-1));
  }

  &:hover,
  &:active {
    background-color: hsl(var(--color-grayscale-3));
  }
`;

export const outline = css`
  ${medium};
  border: 1px solid hsl(var(--color-borderLight-8));

  path {
    fill: hsl(var(--color-text-1));
  }

  &:hover,
  &:active {
    border: 1px solid hsl(var(--color-borderBlack-2));
  }
`;

export const disabled = css`
  ${opacity.o50};
  pointer-events: none;
`;

export const loading = css`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: none;
      stroke: hsl(var(--color-text-1));
    }
  }

  &:hover {
    path {
      fill: none;
      stroke: hsl(var(--color-text-1));
    }
  }
`;

export const loadingPrimary = css`
  ${loading};

  svg {
    path {
      fill: none;
      stroke: hsl(var(--color-white-11));
    }
  }

  &:hover {
    path {
      fill: none;
      stroke: hsl(var(--color-white-11));
    }
  }
`;
